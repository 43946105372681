@import '../../variables';

@media screen and (min-width: 768px) {
    #carousel{
        height: 100vh;
        #slide-opt-list{
            margin: 0;
            padding: 0;
            display: block;
            position:relative;
            top: -50%;
            right: -100%;
            list-style: none;
            transform: translate(-4% , 30%);
            .slide-opt-list-item{
                margin: 0;
                padding: 0;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $bg-secondary;
                margin-bottom: 20px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                opacity: 0.4;
                transform: translate(0% , -50%);
                &.selected,
                &:hover{
                    opacity: 1;
                }
            }
        }
        .carousel-sub-container{
            margin: 0;
            padding: 0;
            display: none;
            width: 100%;
            height: 100%;
            transition: .3s;
            .slide-content{
                padding-top: 8vw;
                padding-right: 18vw;
                padding-left: 18vw;
            }
        }
        #slide-1{
            background-image: url('/images/landing/carousel/slide-1-bg.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            #slide-1-content{
               
                .slide-title,
                .slide-subtitle{
                    color: $text-white;
                }
                .slide-title{
                    width: 180%;
                    padding-bottom: 4vh;
                    font-weight: 600;
                    letter-spacing: 0.6px;
                    margin-bottom: 0;
                }
                .slide-subtitle{
                    font-weight: 500;
                    padding-bottom: 5vh;
                    font-size: 1.18em;
                }
                .slide-1-btn{
                    display: block;
                }
                #slide-1-wp-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, .5);
                    color: $text-white;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    i {
                        margin-right: 4px;
                        font-size: 1.2em;
                    }
                }
                #slide-1-message-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: #FFFFFF;
                    color: $primary;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    margin-top: 15px;
                    font-weight: 600;
                }
            }
            #slide-1-image{
                width: 72vh;
            }
            
        }
        #slide-2{
            background-image: url('/images/landing/carousel/slide-2-bg.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            #slide-2-content{
                .slide-title,
                .slide-subtitle{
                    color: $text-white;
                    text-align: center;
                    width: 100%;
                }
                .slide-title{
                    padding-bottom: 4vh;
                    font-weight: 600;
                    letter-spacing: 0.6px;
                    margin-bottom: 0;
                }
                .slide-subtitle{
                    font-weight: 500;
                    padding-bottom: 5vh;
                    font-size: 1.18em;
                }
                .slide-2-btn{
                    display: block;
                }
                #slide-2-wp-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, .5);
                    color: $text-white;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0 auto;
                    i {
                        margin-right: 4px;
                        font-size: 1.2em;
                    }
                }
                #slide-2-message-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: #FFFFFF;
                    color: $primary;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    margin: 15px auto 0 auto;
                    font-weight: 600;
                }
            }
            
        }
        #slide-3{
            background-image: url('/images/landing/carousel/slide-3-bg.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 10vh;
            #slide-3-content{
                text-align: center;
                margin: -5vh auto 4vh auto;
                .slide-title,
                .slide-subtitle{
                    color: $text-white;
                    text-align: center;
                    width: 100%;
                }
                .slide-title{
                    padding-bottom: 1vh;
                    font-weight: 600;
                    letter-spacing: 0.6px;
                    margin-bottom: 0;
                }
                .slide-subtitle{
                    font-weight: 500;
                    padding-bottom: 2vh;
                    font-size: 1.18em;
                }
                .slide-3-btn{
                    display: inline-block;
                    margin: 0 auto;
                }
                #slide-3-wp-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, .5);
                    color: $text-white;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0 4px;
                    i {
                        margin-right: 4px;
                        font-size: 1.2em;
                    }
                }
                #slide-3-message-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: #FFFFFF;
                    color: $primary;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0 4px;
                }
            }
            #slide-3-image{
                width: 75%;
                min-width: 350px;
                margin: 0 auto;
                display: block;
            }
            
        }
    }
    #what-we-do{
        padding-top: 3vh;
        padding-bottom: 3vh;
        background-image: url('/images/landing/services/background.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-right: 18vw;
        padding-left: 18vw;
        #what-we-do-title{
            text-align: center;
            padding: 2vh 0 4vh 0;
            color: $text-white;
            margin: 0;
            font-weight: 600;
            font-size: 1.6rem;
            letter-spacing: 0.6px;
        }
        #boxes-container{
            margin: 0;
            padding: 0;
            .box-row{
                margin: 0 0 6vh 0;
                .box-container{
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #FFFFFF;
                    border-radius: 27px;
                    min-height: 58vh;
                    padding: 1vh 1%;
                    text-align: center;
                    width: 30%;
                    min-width: 250px;
                    max-width: 450px;
                    cursor: pointer;
                    transition: .3s;
                    background-color: rgba(255, 255, 255, .25);
                    .img-container{
                        height: 35%;
                        .box-image{
                            height: 100%;
                            width: auto;
                        }
                    }
                    .box-title{
                        color: $text-white;
                        padding-bottom: 2vh;
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .box-description{
                        color: $text-white;
                        font-weight: 300;
                        padding-bottom: 1vh;
                        min-height: 25%;
                        line-height: 20px;
                    }
                    .box-link{
                        color: $text-white;
                        text-decoration: none;
                        border: 2.5px solid #FFFFFF;
                        border-radius: 54px;
                        width: 170px;
                        padding: 2px 0;
                        display: block;
                        margin: 0 auto;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                    &:hover{
                       background-color: #FFF;
                       .img-container{
                            
                        }
                        .box-title{
                            color: $primary;
                        }
                        .box-description{
                            color: $text-black;
                            font-weight: 400;
                        }
                        .box-link{
                            background-color: $secondary;
                            border-color: $secondary;
                        }
                    }
                    
                }
            }
        }
    }
    #about-us{
        background-color: #FFF;
        padding: 8vh 0;
        width: 100%;
        #about-us-image-container{
            width: 33vw;
            height: 33vw;
            background-image: url('/images/landing/about-us/img-1.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: .5s;
        }
        #about-us-text-container{
            width: 34vw;
            padding: 0 5vh;
            text-align: center;
            #about-us-title{
                color: $primary;
                margin: 0;
                padding: 0 0 4vh 0;
                font-weight: 600;
                font-size: 1.6rem;
                letter-spacing: 0.6px;
            }
            #about-us-description{
                color: $text-black;
                font-weight: 400;
                margin: 0;
                padding: 0 0 4vh 0;
                line-height: 20px;
            }
            #about-us-link{
                color: $text-white;
                text-decoration: none;
                border: 2.5px solid $primary;
                background-color: $primary;
                border-radius: 54px;
                width: 200px;
                padding: 3px 0;
                display: block;
                margin: 0 auto;
                transition: .3s;
                font-weight: 500;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        #about-us-metrics-container{
            margin: 0;
            padding: 2vh 0 0 0;
            width: 100%;
            text-align: center;
            .about-us-metric-box{
                .about-us-metric-box-title{
                    font-size: 3.5em;
                    color: $primary;
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }
                .about-us-metric-box-subtitle{
                    color: $primary;
                    align-self: end;
                    font-weight: 700;
                }

                .about-us-metric-box-description{
                    font-size: 1.5em;
                    color: $text-gray;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    
    
}
@media screen and (max-width: 768px) {
    #carousel{
        height: 100vh;
        #slide-opt-list{
            margin: 0;
            padding: 0;
            display: block;
            position:relative;
            top: 0%;
            left: 0%;
            list-style: none;
            transform: translate(0% , -100%);
            text-align: center;
            .slide-opt-list-item{
                margin: 0 5px;
                padding: 0;
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $bg-secondary;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                opacity: 0.4;
                transform: translate(0% , -50%);
                &.selected,
                &:hover{
                    opacity: 1;
                }
            }
        }
        .carousel-sub-container{
            margin: 0;
            padding: 0;
            display: none;
            width: 100%;
            height: 100%;
            transition: .3s;
            .slide-content{
                padding-top: 8vw;
                flex-direction: column;
            }
        }
        #slide-1{
            background-image: url('/images/landing/carousel/slide-1-bg.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            #slide-1-content{
                text-align: center;
                .slide-title,
                .slide-subtitle{
                    color: $text-white;
                }
                .slide-title{
                    width: 100%;
                    padding-bottom: 2vh;
                    font-weight: 600;
                    letter-spacing: 0.6px;
                }
                .slide-subtitle{
                    font-weight: 500;
                    padding-bottom: 2vh;
                    font-size: 1.18em;
                }
                .slide-1-btn{
                    display: block;
                }
                #slide-1-wp-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, .5);
                    color: $text-white;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0 auto;
                    i {
                        margin-right: 4px;
                        font-size: 1.2em;
                    }
                }
                #slide-1-message-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: #FFFFFF;
                    color: $primary;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 10px auto 0 auto;
                }
            }
            #slide-1-image{
                width: 50vw;
                margin: 0 auto;
            }
            
        }
        #slide-2{
            background-image: url('/images/landing/carousel/slide-2-bg.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            #slide-2-content{
                .slide-title,
                .slide-subtitle{
                    color: $text-white;
                    text-align: center;
                    width: 100%;
                }
                .slide-title{
                    width: 100%;
                    padding-bottom: 2vh;
                    font-weight: 600;
                    letter-spacing: 0.6px;
                }
                .slide-subtitle{
                    font-weight: 500;
                    padding-bottom: 2vh;
                    font-size: 1.18em;
                }
                .slide-2-btn{
                    display: block;
                }
                #slide-2-wp-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, .5);
                    color: $text-white;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0 auto;
                    i {
                        margin-right: 4px;
                        font-size: 1.2em;
                    }
                }
                #slide-2-message-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: #FFFFFF;
                    color: $primary;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 10px auto 0 auto;
                }
            }
            
        }
        #slide-3{
            background-image: url('/images/landing/carousel/slide-3-bg.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 5vh;
            #slide-3-content{
                margin-bottom: 3vh;
                .slide-title,
                .slide-subtitle{
                    color: $text-white;
                    text-align: center;
                    width: 100%;
                }
                .slide-title{
                    width: 100%;
                    padding-bottom: 2vh;
                    font-weight: 600;
                    letter-spacing: 0.6px;
                }
                .slide-subtitle{
                    font-weight: 500;
                    padding-bottom: 2vh;
                    font-size: 1.18em;
                }
                .slide-3-btn{
                    display: block;
                }
                #slide-3-wp-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: rgba(255, 255, 255, .5);
                    color: $text-white;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 0 auto;
                    i {
                        margin-right: 4px;
                        font-size: 1.2em;
                    }
                }
                #slide-3-message-btn{
                    border: 3px solid #FFFFFF;
                    border-radius: 32px;
                    background-color: #FFFFFF;
                    color: $primary;
                    padding: 6px 0;
                    width: 265px;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 10px auto 0 auto;
                }
            }
            #slide-3-image{
                width: 100%;
                margin: 0 auto;
                display: block;
            }
            
        }
    }
    #what-we-do{
        padding-top: 3vh;
        padding-bottom: 3vh;
        
        #what-we-do-title{
            text-align: center;
            padding: 0 0 6vh 0;
            color: $text-white;
            margin: 0;
        }
        #boxes-container{
            margin: 0;
            padding: 0;
            .box-row{
                flex-direction: column;
                margin: 0;
                .box-container{
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #FFFFFF;
                    border-radius: 27px;
                    min-height: 35vh;
                    padding: 1vh 1%;
                    text-align: center;
                    width: 100%;
                    margin: 0 0 3vh 0;
                    cursor: pointer;
                    transition: .3s;
                    background-color: rgba(255, 255, 255, .25);
                    .img-container{
                        height: 16vh;
                        .box-image{
                            height: 100%;
                            width: auto;
                        }
                    }
                    .box-title{
                        color: $text-white;
                        padding-bottom: 2vh;
                        margin: 0;
                    }
                    .box-description{
                        color: $text-white;
                        font-weight: 300;
                        padding-bottom: 0;
                        min-height: 25%;
                    }
                    .box-link{
                        color: $text-white;
                        text-decoration: none;
                        border: 2.5px solid #FFFFFF;
                        border-radius: 54px;
                        width: 170px;
                        padding: 2px 0;
                        display: block;
                        margin: 0 auto;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                    &:hover{
                       background-color: #FFF;
                       .img-container{
                            
                        }
                        .box-title{
                            color: $primary;
                        }
                        .box-description{
                            color: $text-black;
                            font-weight: 400;
                        }
                        .box-link{
                            background-color: $secondary;
                            border-color: $secondary;
                        }
                    }
                    
                }
            }
        }
    }
    #about-us{
        background-color: #FFF;
        padding: 5vh 0;
        width: 100%;
        #about-us-image-container{
            width: 80vw;
            height: 80vw;
            background-image: url('/images/landing/about-us/img-1.webp');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: .5s;
            margin: 0 auto;
        }
        #about-us-text-container{
            width: 100%;
            margin-bottom: 4vh;
            text-align: center;
            #about-us-title{
                color: $primary;
                margin: 0;
                padding: 2vh 0 2vh 0;
                font-weight: 600;
                font-size: 1.6rem;
                letter-spacing: 0.6px;
            }
            #about-us-description{
                color: $text-black;
                font-weight: 400;
                margin: 0;
                padding: 0 0 2vh 0;
                line-height: 20px;
            }
            #about-us-link{
                color: $text-white;
                text-decoration: none;
                border: 2.5px solid $primary;
                background-color: $primary;
                border-radius: 54px;
                width: 200px;
                padding: 3px 0;
                display: block;
                margin: 0 auto;
                transition: .3s;
                font-weight: 500;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        #about-us-metrics-container{
            margin: 0;
            padding: 0;
            display: inline-block;
            width: 100%;
            text-align: center;
            .about-us-metric-box{
                width: 32%;
                .about-us-metric-box-title{
                    font-size: 2.5em;
                    color: $primary;
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }
                .about-us-metric-box-subtitle{
                    color: $primary;
                    align-self: end;
                    font-weight: 700;
                }
                .about-us-metric-box-description{
                    font-size: 1.3em;
                    color: $text-gray;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    
}